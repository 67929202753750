@import "../../../../styles/scss/Internal/variables";

.company-finder {
  .aur-button {
    height: 41px;
    font-size: 18px;
    font-weight: 600;
    margin-top: 14px;
  }

  &-field {
    &.aur-input-container {
      margin: 145px 0 0 0;
    }
  }
}
