@import "../../../../styles/scss/Internal/variables";

.error-field {
  &-container {
    padding: 20px;
  }

  background: $error-background-color;
  border: 1px solid $error-red-color;
  padding: 8px;
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  overflow-wrap: break-word;
  border-radius: 2px;

  &-title {
    font-weight: bold;
    padding-bottom: 10px;
  }
}
