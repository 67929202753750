@import '../../../../styles/scss/Internal/variables';

.cen-button-menu-icon {
  position: relative;
  top: -4px;
  color: $primary-brand-color;
  font-size: 12px;
  padding-left: 2px;
  cursor: pointer;
}

.cen-button-popover {
  max-width: 250px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 5px;
  white-space: normal;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);

  .aur-button {
    text-align: start;
  }

  // Elevated priority select to override automatic styling
  &#{&} {
    margin-top: 5px;
  }
}
