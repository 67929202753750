@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin adaptive-response {
    @media (max-width: $adaptive-breakpoint) {
        @content;
    }
}

@mixin input-border-focused {
  border:1px solid $primary-brand-color;
}

@mixin sanitize-border-box {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
}

@mixin input-placeholder($color) {
    // Safari, Chrome, Opera
    &::-webkit-input-placeholder {
        color: $color;
    }

    // Firefox 4-18
    &:-moz-placeholder {
        color: $color;
    }

    // Firefox 19+
    &::-moz-placeholder {
        color: $color;
    }

    // IE 10, 11
    &:-ms-input-placeholder {
        color: $color;
    }
}

@mixin center-child-element() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin user-select($value) {
    -webkit-touch-callout: $value;  /* iOS Safari */
    -webkit-user-select  : $value;  /* Safari */
    -khtml-user-select   : $value;  /* Konqueror HTML */
    -moz-user-select     : $value;  /* Firefox */
    -ms-user-select      : $value;  /* Internet Explorer/Edge */
    user-select          : $value;  /* Non-prefixed version, currently
                                       supported by Chrome and Opera */
}

@mixin flex($value) {
  flex: $value;
  -ms-flex: $value;
}

@mixin display-flex() {
  display: flex;
  display: -ms-flexbox;
}

@mixin toggle-smooth-slide() {
  -webkit-transition: left 0.2s, right 0.2s;
  transition: left 0.2s, right 0.2s;
}

@mixin toggle-smooth-background-color-change() {
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
