@import "../../../../styles/scss/Internal/variables";

.product-button-icon {
  &-container {
    display: flex;
    flex-wrap: wrap;
  }

  &-selection {
    margin: 4px;
    width: 84px;
    height: 84px;
    border-radius: $default-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 100%;
    }

    &:focus {
      outline: none;
      border: 2px solid $primary-brand-color;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.1);
    }
  }

  &-selected {
    background: $row-highlight-color;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.1);
  }
}