@import "./Internal/variables";

.modal-alert {
  .aur-modal-content-header-close-icon {
    cursor: pointer;
  }

  .aur-modal-shell {
    width: 400px;

    .aur-modal-content {
      padding: 0 16px;
      font-size: 14px;

      &-header {
        border-bottom: none;
        height: unset;
        line-height: unset;
        margin-top: 17px;

        &-icon {
          font-size: 18px;
          padding: 0 8px 0 0;
        }

        &-title {
          font-size: 18px;
          line-height: 22px;
          padding: unset;
        }

        &-close-icon {
          color: #888;
          padding: unset;
          margin-bottom: auto;
        }
      }

      &-body {
        padding: 15px 0 20px 0;
        line-height: 17px;
      }

      hr {
        border: 1px solid #ddd;
        border-bottom: none;
        margin: unset;
      }

      &-footer {
        padding: 16px 0;

        .aur-button {
          font-size: 14px;

          &:focus {
            outline: none;
            box-shadow: none;
            filter: opacity(0.75);
          }
        }

        &-cancel-button {
          padding: 0;
          text-align: right;
        }
      }
    }
  }
}
