@import '../../../../styles/scss/Internal/variables';

.aur-icon-table-row-button {
    padding: 0;
    height: 56px;

    .aur-icon-table-row {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 16px;
        color: $font-primary-color;

        &-icon-container {
            width: 35px;
            border-right: 1px solid $font-line-primary-color;
            margin-right: 10px;
            flex: none;
        }

        &-icon {
            background-color: #666666;
            color: white;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: flex;

            &-text {
                font-weight: bold;
                align-self: center;
                text-align: center;
                margin-bottom: 2px;
                width: 100%;
            }
        }
    }

    .aur-icon-table-row:hover,
    .aur-icon-table-row:focus {
        background-color: $background-color;
    }
}
