.aur-application-body {
  @include adaptive-response {
    margin-left: 0;
    margin-right: 0;
  }
}

.aur-application-body-empty {
  height: calc(#{$application-body-height} - #{$navigation-container-height});
  display: flex;
  flex-direction: column;
  font-size: $font-smaller-text-size;

  @include adaptive-response {
    margin-left: 0;
    margin-right: 0;
  }
}

// Override style for IE11
.aur-popover {
  & > div > .aur-button {
    position: relative;
    right: 0;
  }

  &.iconAnchor {
    left: 0 !important;
  }
}