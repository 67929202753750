@import "../../../../styles/scss/Internal/variables";

.message-container {
  .message {
    font-size: 43px;
    font-weight: bold;
    line-height: 57px;

    &.compact {
      font-size: $font-h3-size;
      line-height: 34px;
      letter-spacing: -0.4px;
    }
  }

  .welcome {
    font-size: $font-h3-size;
    font-weight: 300;
    line-height: 32px;

    &.compact {
      font-size: $font-h6-size;
      font-weight: 100;
      letter-spacing: -0.48px;
    }
  }

  .compact {
    text-align: center;
    display: block;
  }
}
