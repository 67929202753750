@import "../../../../styles/scss/Internal/variables";

.product-selection {

  // Hack for IE11
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    width: 100%;
  }

  &-title {
    display: flex;
    text-align: left;
    padding-left: 100px;
    color: $font-primary-color;
    font-size: 14px;
    letter-spacing: -0.42px;
    margin-bottom: 20px;
    transition: opacity 0.3s ease-out;
    opacity: 1;
    align-items: center;

    @media screen and (max-width: $screen-width-compact) {
      text-align: unset;
      padding-left: unset;
    }

    &.hidden {
      height: 0px;
      margin-bottom: none;
      opacity: 0;
    }

    .hidden {
      opacity: 0;
    }

    .aur-button.borderless {
      margin-left: auto;
      background: none;
      font-size: 14px;
      margin-right: 75px;

      &.hidden {
        display: none;
      }

      .aur-icon {
        margin-right: 6px;
      }
    }
  }

  .fa-spinner {
    margin-top: 8px;
    font-size: 24px;
  }

  .no-products-message {
    font-size: $font-h6-size;
    letter-spacing: -0.72px;
    line-height: 21px;
    margin-bottom: 20px;
    margin-top: 8px;
    max-width: 500px;
    transition: opacity 0.3s ease-out;
    opacity: 1;

    & > a {
      color: $primary-brand-color;
    }

    &.hidden {
      opacity: 0;
      height: 0;
      margin: unset;
    }
  }

  &-list {
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    color: $primary-brand-color;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    @media screen and (max-width: $screen-width-compact) {
      padding: 15px 0 40px 0;
    }

    &-cell {
      margin: 0 10px 20px 10px;
      width: 220px;
      height: 150px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
      position: relative;

      .cen-button-menu-icon-container {
        margin-left: auto;
        position: relative;
      }

      &-decoration {
        position: absolute;
        top: -6px;
        left: -7px;
      }

      .font-awesome-button {
        padding: 3px 12px;

        color: $font-placeholder-color;
        font-size: 20px;

        &:hover {
          outline: none;
        }
      }

      &-divider {
        width: 200px;
        border-top: 1px solid $font-line-primary-color;
      }

      &:focus:not(.disabled) {
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
        background: white;
        transition: all 0.15s ease-in-out;
        outline: none;
      }

      &:hover:not(.disabled) {
        box-shadow: 0 6px 6px 0 rgba(0,0,0,0.26), 0 10px 20px 0 rgba(0,0,0,0.19);
        background: white;
        transition: all 0.15s ease-in-out;
      }

      &.disabled {
        filter: grayscale(100%);
        background: rgba(230, 230, 230, 0.5);
      }

      @media screen and (max-width: $screen-width-compact) {
        width: 100%;
      }

      &-title {
        overflow: hidden;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        color: $font-primary-color;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        word-break: break-word;

        // Hack for IE
        width: 100%;

        &.single-line-clamp {
          -webkit-line-clamp: 1; 
        }
      }

      &-subtitle {
        font-size: 12px;
        color: $font-primary-color;
        margin-top: 4px;
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -16px;
        height: 55px;

        // Hack for IE11
        width: 100%;

        svg {
          height: 40px;
          margin: 6px 0;
        }

        &.custom-logo {
          svg {
            height: 34px;
            min-height: 34px;
            min-width: 120px;
            margin: 9px 0;
          }
        }
      }

      &-icon, &-title, &-subtitile {
        padding: 0 15px;
      }

      &-footer {
        &-info, &-error {
          min-height: 49px;
          margin-top: auto;
          width: 100%;
          font-size: 13px;
          line-height: 17px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          // Hack for IE11
          height: 49px;
        }

        &-info {
          background: $row-highlight-color;
          color: $blue-400;

          > span:first-child {
            font-weight: bold;
          }
        }

        &-error {
          background: $alert-background-color;
          color: $red-300;
          padding: 0 10px;

          .aur-icon {
            font-size: 12px;
            padding-right: 4px;
          }
        }
      }
    }
  }

  .aur-popover {
    padding: unset;

    & > div > .aur-button {
        height: 30px;
        min-width: 142px;
        padding: 0 14px;
        color: $font-primary-color;
        font-weight: 400;
        font-size: 14px;
        justify-content: flex-start;
        margin-bottom: none;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $row-highlight-color;
            box-shadow: none;
        }
    }

    .cen-button-popover-content {
      display: flex;
      flex-direction: column;
    }

    &.iconAnchor {
        margin-top: 8px !important;
    }
  }

  &-remove {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &-icon.product-selection-remove-icon {
      font-size: 44px;
      padding-bottom: 18px;
    }

    &-title {
      font-size: 20px;
      padding-bottom: 18px;
    }

    &-body {
      padding: 20px;
    }

    &-icon, &-title {
      color: $primary-orange-color
    }
  }

  &-delete-button.product-selection-delete-button {
    &.aur-button.primary {
      min-width: 146px;
      background: $alert-red-color;
      border-color: $alert-red-color;
    }
  }
}