@import "../../../../styles/scss/Internal/variables";
@import "../../../../styles/scss/modalAlert.scss";

#product-button-display {
  @extend .modal-alert;
  
  .aur-modal-content-header.aur-modal-content-header {
    margin-top: 12px;
  }

  .aur-modal-content-header-icon.aur-modal-content-header-icon {
    padding: unset;
  }

  .aur-modal-content-header-title.aur-modal-content-header-title {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .aur-modal-content-body.aur-modal-content-body {
    padding-bottom: 0px;
  }

  .aur-label {
    margin-bottom: 12px;
    .aur-label__error {
      height: 0;
    }

    .aur-label__element {
      margin-top: 5px;
    }
  }

  &-name-field-label {
    margin-bottom: 6px;
  }

  &-name-input {
    color: $font-light-color;
    background: $font-disabled-color;
  }

  &-secondary-label {
    margin-bottom: 12px;
    font-size: 14px;
  }

  .product-button-display {
    &-save-button {
      min-width: 73px;
    }
    
    &-secondary-label {
      margin-bottom: 12px;
      font-size: 14px;
      color: $primary-brand-color;
      margin: 0 0 18px 0;

      .checkmark {
        border-color: $primary-brand-color;
      }
    }
  }

  .error-field-container {
    padding: 0 0 20px 0;
  };
}