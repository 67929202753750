@import "../../../../styles/scss/Internal/variables";
$login-container-width: 500px !default;
$notification-width: 500px;

.aur-application-body-login {
  display: flex;
  justify-content: space-between;
  z-index: 20;
  position: relative;

  @media screen and (max-width: $screen-width-compact) {
    z-index: 0;
    height: 100%;
    justify-content: center;
  }

  .notification-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    // simple overlay
    @media screen and (max-width: $screen-width-compact) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 1;
      display: flex;
      width: 100vw;
      justify-content: flex-start;

      &.hidden {
        display: none;
      }
    }
  }

  .social-note,
  .system-alert {
    max-width: $notification-width;
    margin: 10px 0 10px 0;
    z-index: 10;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: calc(100vh - #{$application-footer-height});
    width: $login-container-width;
    background: rgba(255, 255, 255, 0.9);
    padding: 133px 53px 22px 53px;
    overflow: auto;
    text-shadow: 0px 1px 4px #ffffff;
    flex-shrink: 0;

    // Hack for IE
    @supports not (-ms-high-contrast: none) {
      align-items: center;
   }

    @media screen and (max-width: $screen-width-compact) {
      min-height: calc(100vh - #{$application-footer-compact-height});
      width: 100%;
      flex-shrink: 1;
      padding-top: 80px;
    }

    .login-container-logo {
      display: flex;
      justify-content: center;
      height: 77px;
      width: 394px;
    }

    .company-finder {
      max-width: 379px;
    }
  }

  .aur-input.aur-input {
    height: 40px;
    font-weight: 300;
    font-size: 16px;
  }

  @keyframes grow1 {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(100%);
    }
  }
  
  @keyframes grow2 {
    0% {
      transform: scaleY(0);
    }
    33% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(100%);
    }
  }
  
  @keyframes grow3 {
    0% {
      transform: scaleY(0);
    }
    66% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(100%);
    }
  }
  
  .corner-stamp {
    transform-origin: 50% 100%;
    bottom: 0;
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    width: 0;
    height: 0;
    border-style: solid;
  
    &-container {
      height: 100%;
      align-self: flex-start;
      position: absolute;

      @media screen and (max-width: $screen-width-compact) {
        &.hide-compact {
          display: none;
        }
      }
    }
  
    &-large {
      top: calc(100vh - 245px);
      animation: 0.6s ease 0s 1 grow1;
      border-width: 245px 0 0 258px;
      border-color: transparent transparent transparent #006DBD;
  
      @media screen and (max-width: $screen-width-compact) {
        border-width: 122px 0 0 129px;
      }
    }
    
    &-medium {
      top: calc(100vh - 168px);
      animation: 0.6s ease 0s 1 grow2;
      border-width: 168px 0 0 258px;
      border-color: transparent transparent transparent #522A7A;
  
      @media screen and (max-width: $screen-width-compact) {
        border-width: 84px 0 0 129px;
      }
    }
    
    &-small {
      top: calc(100vh - 109px);
      animation: 0.6s ease 0s 1 grow3;
      border-width: 109px 0 0 258px;
      border-color: transparent transparent transparent #011C72;
  
      @media screen and (max-width: $screen-width-compact) {
        border-width: 54px 0 0 129px;
      }
    }
  }
}

.aur-input {
  *::-ms-backdrop,
  &.with-icon {
    // Hack for IE 11
    @media all and (-ms-high-contrast: none) {
      text-indent: 25px !important;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .open-menu-button {
    position: absolute;
    right: -50px;
  }

  *::-ms-backdrop,
  .iconAnchor {
    left: -10px !important;
  }
}