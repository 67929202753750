@import "../../../../styles/scss/Internal/variables";

.system-alert {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: all 0.6s ease-in-out;

  &.hidden {
    max-height: 0 !important;
    opacity: 0;
    padding: 0;
    margin: 0 !important;
    overflow: hidden;
  }

  @media screen and (max-width: $screen-width-compact) {
    margin: 22px 15px 0 15px;
    background: white;
  }

  &-content {
    border-top: 5px solid $primary-orange-color;
    border-bottom: 5px solid $primary-orange-color;
    padding: 10px 16px 16px 16px;
    margin-top: 34px;
    margin-bottom: 13px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $screen-width-compact) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  &-icon {
    font-size: 45px !important;
    color: $primary-orange-color;
    align-self: center;
    padding-bottom: 4px;
  }

  @media screen and (min-width: calc(#{$screen-width-compact} + 1px)) {
    .collapse-icon-span {
      border: solid #ddd;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 15px;
      height: 15px;
      // border-width: 5px 0 0 5px;
      border-width: 0;
      align-self: center;
      margin-bottom: 5px;
    }
  }

  &-title {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 7px;
  }

  &-message {
    font-size: 13px;
    line-height: 17px;
    color: #888;
    margin-bottom: 15px;
    overflow: auto;
  }

  .aur-checkbox-option {
    font-weight: 500;
    margin: 0 3px 5px 3px;

    &-label {
      font-weight: 500;
    }
  }

  &-required {
    border: 1px solid $error-red-color;
    background-color: $error-background-color;
    text-align: center;
    padding: 10px;
    margin: 15px 0 21px 0;
    font-size: $font-small-text-size;
    transition: all 0.4s ease-in-out;
    max-height: 50px;
    overflow: hidden;

    &.hidden {
      max-height: 0;
      opacity: 0;
      padding: 0;
      margin: 10px 0 11px;
    }
  }

  .aur-button {
    font-size: 14px;
    height: 32px;
    min-width: 130px;
    align-self: center;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  }
}
