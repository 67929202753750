@import "../../../../styles/scss/Internal/variables";

.home-layout {
  overflow-y: hidden;

  .title-layout {
    height: $application-home-title-height;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 30px;
    color: white;
    letter-spacing: -0.72px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background: linear-gradient(
      180deg,
      rgba(0, 67, 105, 0.98) 0%,
      rgba(14, 75, 110, 0.25) 75%,
      rgba(255, 255, 255, 0) 100%
    );

    &.compact {
      padding-left: 0px;
      height: $application-home-title-compact-height;
    }
  }

  .content-layout {
    display: flex;
    justify-content: center;
    padding: 24px 50px;
    text-align: center;
    min-height: calc(
      100vh - #{$application-header-height} - #{$application-home-title-height} - #{$application-footer-height}
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.8) 93.2%,
      rgb(255, 255, 255) 100%
    );

    &.compact {
      min-height: calc(
        100vh - #{$application-header-height} - #{$application-home-title-compact-height} -
          #{$application-footer-compact-height}
      );
    }
  }
}
