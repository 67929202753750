@import "../../../../styles/scss/Internal/variables";

.id-provider {
  &-container {
    & > .aur-button.aur-button {
      border-width: 0;
      background-color: transparent;
      height: 56px;
      padding: 0;
      margin-bottom: 30px;
      justify-content: flex-start;

      & > .aur-icon-table-row {
        padding: unset;
        font-size: 20px;
        line-height: 27px;
        text-align: left;

        & > .aur-icon-table-row-text {
          overflow: hidden;
          /* autoprefixer: off */
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-word;
        }

        .aur-icon-table-row-icon {
          height: 38px;
          width: 38px;
          font-size: 20px;

          &-text {
            margin-bottom: none;
          }
        }

        & > .aur-icon-table-row-icon-container {
          width: 38px;
          height: 38px;
          margin-right: 22px;
          display: flex;
          border: none;
          justify-content: center;

          & > img {
            align-self: center;
            max-width: 38px;
            max-height: 38px;
          }
        }
      }
    }
  }

  &-title-container {
    margin: 23px 0 30px 0;
    text-align: left;

    &.secondary {
      margin: 34px 0 24px 0;
    }
  }

  &-separator {
    height: 1px;
    box-sizing: border-box;
    border-top: 1px solid #979797;
    margin-bottom: 32px;
  }

  &-title {
    font-size: 18px;
    line-height: 24px;
  }
}
