@import "../../../../styles/scss/Internal/variables";

.login-form {
  text-align: left;

  &-title {
    margin-top: 66px;
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 23px;
    min-width: 380px;

    & > span {
      font-weight: 600;
    }
  }

  &-message {
    margin-bottom: 20px;
  }

  &-field {
     &.aur-input-container {
      margin-top: 11px;
     }
  }

  .aur-button {
    height: 41px;
    font-size: 18px;
    font-weight: 600;
  }

  &-forgot-password {
    margin-top: 21px;
    text-align: left;
    line-height: 21px;
    font-size: 16px;
    letter-spacing: -0.2px;
    display: none;

    & > a {
      text-decoration: none;
    }
  }

  &-loading-icon {
    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    margin-top: 82px;
    font-size: 30px;
  }
}
