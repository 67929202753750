@import "./Internal/variables";
@import "./modalAlert.scss";

#modal-delete {
  @extend .modal-alert;

  .aur-modal-shell {
    .aur-modal-content {
      &-header-icon {
        color: $alert-red-color;
      }

      .action-button {
        background-color: $alert-red-color;
        border-color: $alert-red-color;
      }
    }
  }
}
