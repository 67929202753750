@use "sass:math";

/** Accruent DSM Colors */
// Grayscale
$black: #000000 !default;
$white: #ffffff !default;
$black-500: $black !default;
$gray-500: $black !default;
$gray-400: #444444 !default;
$gray-300: #666666 !default;
$gray-200: #888888 !default;
$gray-100: #dddddd !default;
$gray-75: #eeeeee !default;
$gray-50: #f9f9f9 !default;
$gray-0: $white !default;

// Blues
$blue-400: #001c71 !default;
$blue-300: #00459b !default;
$blue-200: #007ac9 !default;
$blue-100: #7ebbe6 !default;
$blue-75: #bcddf5 !default;
$blue-50: #e3f2fc !default;
$accruent-blue: $blue-200 !default;

// Greens
$green-400: #1d5c13 !default;
$green-300: #3b852e !default;
$green-200: #39a410 !default;
$green-100: #8de57f !default;
$green-50: #ecffe9 !default;

// Teal
$teal-300: #00696e !default;
$teal-200: #03958e !default;
$teal-100: #56d0c4 !default;
$teal-50: #dcfdfa !default;

// Orange
$orange-300: #c64a00 !default;
$orange-200: #f07200 !default;
$orange-100: #ffa869 !default;
$orange-50: #ffedd6 !default;

// Red
$red-300: #b41f23 !default;
$red-200: #df383b !default;
$red-100: #ff8888 !default;
$red-50: #ffe6e6 !default;

// Purple
$purple-400: #4f2c86 !default;
$purple-300: #62429e !default;
$purple-200: #8661c9 !default;
$purple-100: #b195e5 !default;
$purple-50: #f1eaff !default;

// Colors
$font-primary-color: $gray-400 !default; //Font Grey
$font-light-color: #606060 !default; //Font Light Grey
$font-product-title-color: #001C71; //Font dark purple
$font-placeholder-color: #888 !default; //Placeholder Grey
$font-disabled-color: #dddddd !default; //Disabled Grey
$font-legacy-disabled-color: #ccc !default; //Old Disabled Grey
$background-disabled-color: #e7e7e7 !default; // Disabled input background color
$font-line-primary-color: #ddd !default; //Line Grey
$background-light-grey: #eee !default; //Background Light Grey
$font-header-grey-color: #f1f1f1 !default; //Tableheader Grey
$input-border-color: #c2c2c2 !default; // Dropdown border color
$background-color: #f7f8f9 !default;
$legacy-dark-background-color: #37424a !default;
$dark-background-color: $gray-400 !default;
$darker-background-color: #404040 !default;
$font-highlight-color: #dcf8ff !default; //Highlight Blue
$row-highlight-color: #eaf6ff !default; //Highlight Row Blue
$row-selected-color: #c0e3f9 !default; //Selected Row Blue
$tab-highlight-color: #bbe0fb !default; //Highlight Tab Blue
$tablist-background-color: #eaf6ff !default; //Tabs list background color
$primary-brand-color: #007ac9 !default; //Accruent Blue
$header-background-color: #0d6bc7 !default; //DSM Header Color
$dark-blue-color: #00459B !default; //Darker blue Color
$small-card-header-color: #033b64 !default;
$primary-legacy-orange-color: #ffac2c !default; //Old Primary Orange
$primary-orange-color: #e88424 !default; //Primary Orange
$accent-purple-color: #802be2 !default; //Accent Purple
$accent-red-color: #f83155 !default; //Accent Red
$error-red-color: #df383b !default; // Error Red
$alert-red-color: #d34242 !default; // Alert Red
$alert-background-color: #ffe6e6 !default; // Alert background color
$accent-legacy-green-color: #5ed255 !default; //Old Accent Green
$accent-green-color: #69be28 !default; // Accent Green
$error-background-color: #fff5f7 !default; //Error Background Color Pink
$hover-grey-color: #606060 !default; //Hover Grey
$warning-color: #e68432 !default; //Warning modal color

// Font Family
// San Francisco font for apple devices represented by "-apple-system, BlinkMacSystemFont" below
$font-primary-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto" !default;

// Font Sizes
$font-primary-size: 14px !default;
$font-h1-size: 29px !default;
$font-h2-size: 26px !default;
$font-h3-size: 24px !default;
$font-h4-size: 22px !default;
$font-h5-size: 20px !default;
$font-h6-size: 16px !default;
$font-error-text-size: 14px !default;
$font-small-text-size: 12px !default;
$font-smaller-text-size: 11px !default;
$font-tiny-text-size: 10px !default;
$font-small-icon-size: 15px !default;

// Width Classes
$screen-width-compact: 767px;
$screen-width-medium: 1023px;
$admin-page-compact-size: 870px !default;

// Page Layout
$page-content-padding-size: 20px !default;
$column-margin: math.div($page-content-padding-size, 2) !default;

// Borders
$default-border: 1px solid $font-line-primary-color !default;
$default-border-focused: 1px solid $primary-brand-color !default;
$default-border-radius: 2px !default;
$adaptive-border-radius: 0 !default;
$default-box-shadow: 0 0 5px $primary-brand-color !default;

//Stacking
$z-index-table: 50 !default;
$z-index-page-footer: 100 !default;
$z-index-sidebar: 200 !default;
$z-index-dropdown: 2000 !default;
$z-index-popover: 4000 !default;
$z-index-modal: 5000 !default;
$z-index-top: 99999999 !default;

// Responsiveness
$adaptive-breakpoint: 1023px !default;

// Navigation
$navigation-container-height: 55px !default;
$navigation-tab-margin: 24px !default;
$navigation-tab-list-left-margin: 0 !default;

// Form Fields including Buttons
$form-field-height: 35px !default;
$form-field-focus-border: $default-border-focused !default;
$form-field-with-icon-height: 42px !default;
$form-field-icon-size: 24px !default;
$form-field-with-icon-margin: 11px !default;

// Cards and Modals
$card-modal-header-height: 45px;
$card-modal-header-divider-border-size: 4px !default;
$card-modal-header-divider-border: $card-modal-header-divider-border-size solid
  $primary-brand-color !default;
$card-modal-header-font-size: $font-h5-size !default;

// Home Page Layout
$application-home-title-height: 200px !default;
$application-home-title-compact-height: 125px !default;

// Application Header
$application-header-height: 50px !default;
$application-header-separator-gap: 8px !default;
$application-header-separator-bottom: $application-header-height -
  $application-header-separator-gap !default;

// Application Footer
$application-footer-height: 50px !default;
$application-footer-compact-height: 111px !default;

// Height calculations
$application-header-and-footer-height: $application-footer-height +
  $application-header-height !default;
$application-header-and-footer-compact-height: $application-footer-compact-height +
  $application-header-height !default;
$application-body-height: calc(100vh - #{$application-header-height}) !default;

// Page Footer
$page-footer-height: 55px !default;
$total-footer-height: $application-footer-height + $page-footer-height !default;

// FontAwesome Unicode Values
$font-awesome-angle-left: "\f104" !default;
$font-awesome-angle-right: "\f105" !default;
$font-awesome-angle-down: "\f107" !default;

// Animation durations
$row-appending-duration: 150ms !default;
