@import "../../../../styles/scss/Internal/variables";
@import "../../../../styles/scss/modalAlert.scss";

#product-button-add-modal {
  @extend .modal-alert;

  .aur-modal-content-header.aur-modal-content-header {
    margin-top: 13px;

    .aur-modal-content-header-title {
      text-transform: none;
    }
  }

  .aur-modal-content-body.aur-modal-content-body {
    padding-bottom: 0;
  }

  .aur-modal-content-header-icon {
    display: none;
  }

  .product-button-add {
    &-message {
      padding: 8px 5px;
    }

    &-save-button {
      min-width: 101px;
    }
  }

  .aur-label {
    margin: 12px 0 24px 0;
  }

  .aur-auto-complete-div {
    font-size: 14px;
    flex-grow: 1;

    // Move clear text icon
    > span {
        right: 42px;
    }

    .Select-control {
      border: 1px solid $input-border-color;

      .Select-arrow-zone {
          border-left: none;
      }

      .Select-value {
          padding-right: 20px;
      }
    }
    .Select-option.is-focused {
        background-color: $row-highlight-color;
    }

    .aur-icon {
      font-size: 14px;

      &.fa-angle-down {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 0;
      }
    }
  }

  .error-field-container {
      padding: 0 0 20px 0;
  };
}