@import "../../../../styles/scss/Internal/variables";

.error-display {
  margin: 26px 0;
  padding: 10px 10px;
  letter-spacing: -0.26px;
  font-size: 11px;
  background-color: $error-background-color;
  border: 1px solid $error-red-color;
  max-height: 300px;
  opacity: 1;
  transition: all 0.3s ease-in-out;

  &.hidden {
    max-height: 0;
    margin: 10px 0;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0);
    opacity: 0;
  }
}
