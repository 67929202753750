@import "../../../../styles/scss/Internal/variables";

.social-note {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background: rgba(3, 59, 101, 0.9);
  display: flex;
  flex-direction: column;
  color: white;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  @media screen and (max-width: $screen-width-compact) {
    margin: 22px 15px 0 15px;
    background: #033b65;
  }

  &-content {
    border-top: 5px solid $primary-brand-color;
    border-bottom: 5px solid $primary-brand-color;
    padding: 10px 16px 15px 16px;
    margin-top: 34px;
    margin-bottom: 13px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $screen-width-compact) {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  &-icon {
    color: #7ebbe6;
    align-self: center;
    padding-bottom: 4px;
  }

  @media screen and (min-width: calc(#{$screen-width-compact} + 1px)) {
    // To be implemented in ticket to collapse
    .collapse-icon-div {
      border: solid $primary-brand-color;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 15px;
      height: 15px;
      // border-width: 5px 0 0 5px;
      border-width: 0;
      align-self: center;
      margin-bottom: 5px;
    }
  }

  &-title {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    padding: 6px 0;
  }

  &-message {
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 10px;
  }

  .aur-button {
    font-size: 14px;
    height: 32px;
    min-width: 130px;
    align-self: center;
    margin-top: 3px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);

    @media screen and (max-width: $screen-width-compact) {
      margin-bottom: 6px;
    }
  }
}
