@import "./variables";

html,
body,
#app,
.aur-application {
  background-color: $background-color;

  &-body {
    height: $application-body-height;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    overflow: auto;

    &.compact {
      height: 100%;
    }
  }

  .aur-button {
    border-radius: 2px;
    min-width: unset;
    box-shadow: unset;
    display: flex;
    align-items: center;
    justify-content: center;

    &.cancel-button {
      color: $primary-brand-color;
      background: white;
    }

    &.secondary {
      background-color: unset;
    }
  }

  .aur-label {
    margin-bottom: 21px;
  }

  .aur-input {
    // Elevated priority select to override webpack reordering the automatic styling
    &#{&} {
      height: 30px;
    }
  }

  .aur-text-area {
    border: 1px solid $font-line-primary-color;
  }

  .aur-required-icon {
    &.hidden {
      display: none;
    }
  }

  .aur-date-picker {
    border-radius: 2px;
  }

  .aur-modal-content-header-close-icon:hover {
    outline: none;
  }

  .clickable {
    cursor: pointer;
  }

  .aur-list-view-title {
    white-space: nowrap;

    .aur-button {
      padding: unset;
    }
  }

  .aur-radio-button-group {
    line-height: 18px;

    input[type="radio" i] {
      margin: 3px 4px 0px 5px;
    }

    legend {
      margin-bottom: 5px;
    }
  }
}

#app,
.aur-application-wrapper-empty {
  background-color: $background-color;
}

body {
  font-family: $font-primary-family;
  color: $font-primary-color;
  font-size: $font-primary-size;
}

h1 {
  font-size: $font-h1-size;
}

h2 {
  font-size: $font-h2-size;
}

h3 {
  font-size: $font-h3-size;
}

h4 {
  font-size: $font-h4-size;
}

h5 {
  font-size: $font-h5-size;
}

h6 {
  font-size: $font-h6-size;
}
